#GeneralWrapper {
	.letter-bloc{
		padding: 45px;
		position: relative;

		@include breakpoint($lg) {
			padding: 45px 50px 0 50px;
		}
		@include breakpoint($md) {
			padding: 25px 30px 0 20px;
	    }
	    @include breakpoint($sm) {
	    	padding: 30px;
	    	overflow: hidden;
	    }
	    @include breakpoint($xxs) {
			padding: 20px 35px;
		}



	    .letter-bloc-left{
	    	@include breakpoint($sm) {
	    		width: 50%;
	    		float: left;
	    	}
	    	@include breakpoint($xxs) {
	    		width: 100%;
	    	}
	    }

	    .letter-bloc-right{
	    	@include breakpoint($sm) {
	    		width: 50%;
	    		float: left;
	    	}
	    	@include breakpoint($xxs) {
	    		width: 100%;
	    		margin-top: 5px;
	    	}
	    }

		.letter-icon{
			position: absolute;
			top: 30px;
			left: -40px;
			width: 50px;

			@include breakpoint($md) {
				top: 20px;
				right: 30px;
				left: auto;
			}
			@include breakpoint($sm) {
				width: 210px;
				right: -60px;
				top: auto;
				bottom: -7px;
				z-index: -1;
				opacity: .2;
			}
			@include breakpoint($xs) {
				right: -50px;
			}
		}

		h2, h3, h4{
			letter-spacing: .3px;
			font-size: ms(4);
			font-weight: 700;

			@include breakpoint($xl) {
				font-size: ms(4)*.9;
			}
			@include breakpoint($xs) {
				font-size: ms(5)*.9;
			}
			@include breakpoint($xxs) {
				font-size: ms(5)*.8;
			}
		}

		h4{
			font-family: 'Geometria Extra Bold';
			text-transform: uppercase;
			margin-bottom: 10px;
		}
		h2{
			font-family: 'Geometria Bold';
		}
		h3{
			font-family: 'Geometria Regular';
			font-weight: 400;
			color: $primary;
			margin-bottom: 10px;
		}

		p{font-size: ms(2);}

		small{
			font-size: ms(1)*.95;
			line-height: ms(4);

			@include breakpoint($sm) {
				width: 100%;
				float: left;
			}

			a{
				color: $primary;

			}
		}


		&.letter-bloc-footer{
			padding: 0 0 25px 0;

			@include breakpoint($xl) {
				padding: 0 0 15px 0;
			}
			@include breakpoint($sm) {
				overflow: visible;
			}

			.letter-icon{
				position: absolute;
				top: 0;
				width: 100px;
				left: -50px;
				z-index: -1;
				opacity: .2;

			}
		}

	}
}