#GeneralWrapper {
	footer {
		padding: 40px 0 50px;

		@include breakpoint($sm) {
			padding: 10px 0 20px;
		}
		@include breakpoint($xs) {
			padding: 20px 0 20px;
		}	

		h2{
			a{
				text-decoration: none;
				font-family: 'Geometria Bold';
				font-weight:  700;
				color: $base;
				font-size: ms(2)*.8;
				margin-top: 25px;
				display: block;

				@include breakpoint($xl) {
					margin-top: 15px;
					font-size: ms(2)*.8;
				}
				@include breakpoint($lg) {
					margin-top: 15px;
					font-size: ms(2)*.75;
				}
				@include breakpoint($sm) {
					margin-top: 10px;
					font-size: ms(2)*.7;
				}
				@include breakpoint($xs) {
					margin-top: 8px;
					font-size: ms(2)*.65;
				}
			}
		}
		hr{
			width: 20px;
			border-color: $primary;
			display: inline-block;
			margin: 10px 0;
			border: 2px solid $primary;

			@include breakpoint($sm) {
				border: 1px solid $primary;
				margin: 8px 0;
				width: 15px;
			}
		}

		.footer-bottom{
			margin-top: 30px;
			display: inline-block;
			width: 100%;

			@include breakpoint($xs) {
				margin-top: 0;
			}

			.bloc-footer-bottom{	
				@include breakpoint($xxs) {
					padding: 0;
				}

				@include breakpoint($xxs) {
					width: 100%;
					margin-top: 20px;
				}

				.follow-button{

					@include breakpoint($xxs) {
						padding: 0;
					}

					.fb-like{
						margin-bottom: 10px;
						float: right;
						
						@include breakpoint($xs) {
							float: left;
							margin-right: 5px;
						}
					}
					.twitter-follow-button{
						float: right;
						@include breakpoint($sm) {
							width: 139px !important;
						}
						@include breakpoint($xs) {
							float: left;
							width: auto !important;
						}
					}
				}
				
				.row{
					@include breakpoint($xxs) {
						margin: 0;
					}
				}

				.nav-footer-min{
					@include breakpoint($xs) {
						height: auto;
						margin-bottom: 15px;
					}
				}

				.diabolo {
					display: inline-block;
					padding-top: 35px;

					img {
						position: relative;
						top: -3px;
						left: -5px;
					}

					b {
						-webkit-transition: color .5s;
						-moz-transition: color .5s;
						-ms-transition: color .5s;
						-o-transition: color .5s;
						transition: color .5s;
					}
					
					img {
						-webkit-transition: transform .5s;
						-moz-transition: transform .5s;
						-ms-transition: transform .5s;
						-o-transition: transform .5s;
						transition: transform .5s;
					}

					&:hover {
						b {
							color: #e9552c;
						}
						
						img {
							-webkit-transform: rotate(230deg);
							-moz-transform: rotate(230deg);
							-ms-transform: rotate(230deg);
							-o-transform: rotate(230deg);
							transform: rotate(230deg);
						}
					}
				}
			}
		}

		.footer-top-nav{
			nav{
				//height: 175px;
//
				//@include breakpoint($lg) {
				//	height: 165px;
				//}
				//@include breakpoint($md) {
				//	height: 185px;
				//}
				//@include breakpoint($sm) {
				//	height: auto;
				//}
			}
		}

		.formulaire-letter-min{
			input[type="submit"]{
				@include breakpoint($lg) {
					padding: 16px 20px !important;
				}
				@include breakpoint($md) {
					padding: 11px 20px 10px !important;
				}
			}
		}
	}

	&.page-interieur{
		footer{
			background-image: url('../../img/back-gradien-primary-footer.png');
		}
	}
}