body {
  margin: 0;
  padding: 0;
}

.pageintrouvable{
  
  margin: 50px auto;

  p{
    font-size: ms(2);
    text-align: center;
    display: block;
    width: 100%;
  }
}

body, html{
  overflow-x: hidden;
  width: 100%;
  font-size: 12px;

  @include breakpoint($xl) {
    font-size: ms(-1)*.96;
  }
  @include breakpoint($lg) {
    font-size: ms(-1)*.95;
  }
  @include breakpoint($md) {
  }
  @include breakpoint($sm) {
  }
  @include breakpoint($xs) {
  }

}

#GeneralWrapper {
  border-top: 10px solid $primary;

  .full-width{
    width: 100% !important;
  }

  input, textarea{
    outline: none !important;

    &:focus{
      outline: none !important;
    }
  }

}

.sigle-sni{
  position: absolute;
  top: -10px;
  left: -60px;
  z-index: -1;
  width: 554px;

  @include breakpoint($xl) {
    width: 400px; 
  }
  @include breakpoint($lg) {
    display: none;
  }
}

.clear{
  clear: both !important;
}
.nopadding{
  padding: 0 !important;
}
.nopadding-right{
  padding-right: 0 !important;
}
.nopadding-left{
  padding-left: 0 !important;
}

.primary-gradient-background{
  position: relative;

  .gradient-background{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../img/back-gradien-primary.png);
    background-repeat: repeat-x;
    opacity: .1;
    pointer-events: none;
    background-size: contain;

    @include breakpoint($sm) {
      height: 78%;
    }
    @include breakpoint($sm) {

    }
  }
}


img {
  border: 0;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


