#GeneralWrapper {
	.content{
		padding: 50px 0;

		@include breakpoint($md) {
			padding: 40px 0;
		}
		@include breakpoint($sm) {
			padding: 40px 0 0;
		}
		@include breakpoint($xs) {
			padding: 30px 0 0;
		}


		h2{
			text-align: center;
			font-family: 'Geometria Regular';
			font-weight:  400;
			font-size: ms(5);
			text-transform: uppercase;
			letter-spacing: 1px;

			@include breakpoint($lg) {
				font-size: ms(5)*.9;
			}

			@include breakpoint($md) {
				font-size: ms(5)*.85;
			}

			@include breakpoint($sm) {
				font-size: ms(5)*.8;
				letter-spacing: .5px;
			}	

			&.title{
				text-align: left;
				text-transform: none;
				margin-bottom: 25px;
			}
		}

		.articles{
			margin-top: 25px;

			.bloc{
				margin-bottom: 30px;

				@include breakpoint($xs) {
					padding: 0;
					margin-bottom: 0;
				}

				&:first-child{
					@include breakpoint($xs) {
						border-top: 5px solid $primary;
					}
				}

				&.min{
					h3{
						height: 35px;

						@include breakpoint($md) {
							height: 30px;
						}
						@include breakpoint($sm) {
							height: 65px;
							padding: 20px;
						}
						@include breakpoint($xs) {
							height: auto;
							padding: 0;
						}
					}
					.bloc-article{

						width: 100%;


						@include breakpoint($sm) {
							padding: 0;
							display: inline-table;
						}

						.image-article{
							@include breakpoint($sm) {
								margin: 0;
							}
						}

						p{
							overflow: hidden;
						}
					}

					.txt-bloc-article{
						.bloc-txt{
							display: block;

							@include breakpoint($sm) {
								padding: 20px;
								margin-bottom: 0;
								.comm-lecture{
									margin-bottom: 0;
								}
							}
							@include breakpoint($xs) {
								padding: 0;
							}
						}
					}
					.image-article{
						height: 115px;
						@include breakpoint($xs) {
							height: 174px;
						}
					}
					.comm-lecture{
						@include breakpoint($xs) {
							margin: 10px 0 0 0;
							a{
								margin: 0;
							}
						}
					}
				
				}

				&.page-int{

					p{
						height: 110px;

						@include breakpoint($lg) {
							height: 100px;
						}
						@include breakpoint($sm) {
							height: 170px;
						}
						@include breakpoint($xs) {
							height: auto;
						}
					}
				}
			}


			.bloc-article{
				border-top: 5px solid $primary;
				//background: url('../../img/back-repeat.jpg');
				background-color: #fff;
				background-size: 150px;
				padding: 20px;

				@include breakpoint($xs) {
					border-top: none;
				}

				h3{
					font-family: 'Geometria Bold';
					font-weight:  700;
					font-size: ms(3)*.95;
					margin-bottom: 0;
					height: 45px;

					@include breakpoint($lg) {
						font-size: ms(3)*.9;
						height: 40px;	
					}
					@include breakpoint($md) {
						font-size: ms(3)*.85;
						height: 38px;	
					}
					@include breakpoint($sm) {
						font-size: ms(3)*.8;	
					}
					@include breakpoint($xs) {
						height: auto;
						margin-bottom: 5px;
					}
				}
				.date{
					color: $primary;
					font-size: ms(1);
					font-family: 'Geometria Regular';
					font-weight: 400;
				}

				p{
					font-family: 'Geometria Light';
					font-weight: 200;
					font-size: ms(2);	

					height: 65px;

					@include breakpoint($md) {
						height: 80px;
					}
					@include breakpoint($sm) {
						height: 65px;
					}
					@include breakpoint($xs) {
						height: auto;
					}
				}

				.row{
					margin-left: -20px;
					margin-right: -20px;
				}

				.image-article{
					background-size: cover;
					height: 240px;
					margin-top: 20px;
					margin-bottom: 20px;
					background-position: center;

					@include breakpoint($lg) {
						height: 182px;
					}

					@include breakpoint($md) {
						height: 149px;
					}

					@include breakpoint($md) {
						height: 110px;
					}

					@include breakpoint($xs) {
						height: 240px;
					}
				}

				.comm-lecture{
					margin: 35px 0 15px 0;

					a{
						margin-right: 15px;

						&:last-child{margin-right: 0;}

						font-family: 'Geometria Bold';
						font-weight: 700;
						font-size: ms(0);
						color: $base;

						@include breakpoint($md) {
							display: block;
							margin-bottom: 10px;
						}

						img{margin-right: 10px;}

						&.commentaire{
							img{
								width: 20px;
							}
						}
						&.lecture{
							img{
								width: 25px;
								@include breakpoint($md) {
									width: 22px;
								}
							}
						}
					}
				}
				.vote{
					img{
						width: 20px;
						position: relative;
						top: -5px;

						&:last-of-type{
							margin-right: 10px;
						}
					}
					font-family: 'Geometria Bold';
					font-weight: 700;
					font-size: ms(0);
					color: $base;

					span{
						font-family: 'Geometria Light';
						font-weight: 200;
						font-size: ms(7);	
						margin-left: 5px;
					}
				}
			}

			.lien-article{
				width: 100%;
				background: $primary;
				display: block;
				padding: 15px 20px;
				font-family: 'Geometria Bold';
				font-weight: 700;
				color: #fff;
				font-size: ms(1);
				letter-spacing: .4px;

				-webkit-transition: background .6s ease;
                -moz-transition: background .6s ease;
                -ms-transition: background .6s ease;
                -o-transition: background .6s ease;
                transition: background .6s ease;

				&:hover{
					background: #b5dc3d;
				}

				img{
					width: 20px;
					margin-right: 10px;
				}
			}
		}
	}
	.parole-de-lecteur{
		overflow: hidden;
		width: 100%;
		padding: 55px 0 75px;
		//background-image: url("../../img/back-repeat-primary.jpg");
		background-color: #E3E9C3;

		@include breakpoint($sm) {
			padding: 40px 0 60px;
		}

		@include breakpoint($xs) {
			padding: 25px 0 40px;
		}

		.container{
			position: relative;

			.sigle-sni{
				width: 250px;
				top: -140px;
				left: 50px;
			}
		}

		.title-date-lecteur{
			h2{
				font-family: 'Alegreya';
				font-weight: 400;
				font-size: ms(12);
				line-height: ms(1);
				margin-bottom: 20px;

				@include breakpoint($md) {
					font-size: ms(11);
					margin-bottom: 10px;
				}
				@include breakpoint($sm) {
					font-size: ms(10);
				}
				@include breakpoint($xs) {
					font-size: ms(8);
				}
				@include breakpoint($xxs) {
					font-size: ms(6);
				}	
			}
			span{
				font-size: ms(2);
				font-family: 'Geometria Bold';
				font-weight: 700;
				text-transform: uppercase;
				color: $primary;
			}
		}

		.txt-lecteur{
			@include breakpoint($xxs) {
				padding-bottom: 20px;
			}

			.slick-slide{
				padding: 0 0 0 55px;	

				@include breakpoint($xs) {
					padding: 0;
				}			
			}

		

			p{
				font-size: ms(3);
				width: 90%;
				margin-bottom: 30px;
				line-height: ms(5);
				position: relative;

				@include breakpoint($md) {
					width: 100%;
					line-height: ms(8)*.95;
					font-size: ms(3)*.95;
				}	
				@include breakpoint($xs) {
					margin: 15px 0;
					line-height: ms(8)*.9;
					font-size: ms(3)*.9;
				}

				&:before{
					content: "";
					background-image: url('../../img/guillemet-left.png');
					width: 25px;
					height: 17px;
					background-size: cover;
					position: absolute;
					top: 0;
					left: -50px;

					@include breakpoint($md) {
						width: 20px;
						height: 14px;
					}
					@include breakpoint($xs) {
						display: none;
					}
				}

				&:after{
					content: "";
					background-image: url('../../img/guillemet-right.png');
					width: 25px;
					height: 17px;
					background-size: cover;
					position: absolute;
					bottom: -20px;
					right: -20px;

					@include breakpoint($md) {
						width: 20px;
						height: 14px;
						right: 10px;
					}	
					@include breakpoint($xs) {
						display: none;
					}
				}

				a{
					&.read-more{
						color: $primary;
					}

					.no-color{
						font-family: 'Geometria Regular';
						font-weight:  400;
						color: black;
					}
				}
				span {
					&.hide-me{
						display: none;
					}
				}
			}
			span{
				&:not(.hide-me) {
					font-size: ms(2);
					font-family: 'Geometria Bold';
					font-weight: 700;
					text-transform: uppercase;
					color: $primary;

					@include breakpoint($xs) {
						float: right;
					}
				}
			}
		}
		&.page-int{
			background: none;
			overflow: visible;
			padding: 0 0 30px;

			@include breakpoint($xs) {
				padding: 0 0 20px;
			}
			@include breakpoint($xxs) {
				padding: 0 0 10px;
			}

			.row{
				@include breakpoint($xs) {
					margin-left: -15px !important;
					margin-right: -15px !important;
				}
			}

			.title-date-lecteur{
				width: 100%;
				margin-bottom: 30px;

				@include breakpoint($xs) {
					margin-bottom: 10px;
					padding: 0;
				}

				h2, span{
					text-transform: none;
					text-align: left;
				}
				span{
					font-family: 'Geometria Regular';
					font-weight: 400;
					font-size: ms(4);

					@include breakpoint($sm) {
						font-size: ms(3);
					}
					@include breakpoint($xxs) {
						font-size: ms(3)*.9;
					}
				}
			}

			.txt-lecteur{
				position: relative;

				.col-xs-12{
					@include breakpoint($xs) {
						margin-top: 20px;
						padding: 0;
					}
				}

				&:before{
					content: "";
					background-image: url("../../img/guillemet-left.png");
					width: 25px;
					height: 17px;
					background-size: cover;
					position: absolute;
					top: 0;
					left: -50px;
				}

				&:after{
					content: "";
					background-image: url("../../img/guillemet-right.png");
					width: 25px;
					height: 17px;
					background-size: cover;
					position: absolute;
					bottom: 0;
					right: 0;
				}

				@include breakpoint($xs) {
					&:after, &:before{
						background: none;
					}
				}	

				span{
					color: $base;
					display: block;
					width: 100%;
					margin-bottom: 10px;

					@include breakpoint($xs) {
						float: left;
					}
				}

				p{
					display: inline-block;

					@include breakpoint($xxs) {
						margin: 10px 0;
					}

					&:after, &:before{
						background: none;
					}
				}
				a{
					color: $primary;
					text-decoration: none;
					font-size: inherit;
					font-family: 'Geometria Light';
					font-weight: 200;
					border-bottom: 1px solid $primary;

					&.bouton{
						background-image: url('../../img/input-form-arrow.png');
						background-image: url('../../img/input-form-arrow.svg');
						background-repeat: no-repeat;
						background-position: left center;
						background-size: 20px;
						font-size: ms(3);
						padding-left: 30px;
						border-bottom: none;

						@include breakpoint($md) {
							background-size: 15px;
							padding-left: 25px;
						}
						
					}
				}
			}

			.sigle-sni{
				top: -60px;
				left: -80px;
				opacity: .6;
			}
		}
	}
	.links{
		margin: auto;
		display: table;
		margin-top: -30px;

		@include breakpoint($xxs) {
			width: 100%;
		}

		a{
			text-transform: uppercase;
			font-family: 'Geometria Extra Bold';
			font-weight:  700;
			font-size: ms(2);
			display: inline-table;
			padding: 15px 25px;
			border-radius: 15px;
			color: #fff;
			letter-spacing: .5px;

			@include breakpoint($sm) {
				padding: 10px 20px;
			}
			@include breakpoint($xxs) {
				width: 100%;
				border-radius: 0;
				text-align: center;
			}

			img{
				width: 25px;
				margin-right: 10px;
			}


			&.btn-temoignage{
				background: $primary;
				//text-shadow: 2px 2px 0 #4b610b;
				//margin-right: 20px;

			}
			&.btn-facebook{
				background: #3a5795;
				text-shadow: 2px 2px 0 #11395e;
			}
		}
	}
	.bloc-introduction{
		padding-top: 30px;
		padding-bottom: 0;

		&.category {
			h1 {
				text-transform: uppercase;
			}
		}

		h1{
			font-family: 'Geometria Light';
			font-weight: 200;
			font-size: ms(7);
			letter-spacing: .5px;

			@include breakpoint($lg) {
				font-size: ms(6);
			}
			@include breakpoint($md) {
				font-size: ms(5);
			}
			@include breakpoint($xs) {
				font-size: ms(4);
			}

		}
		h2{
			font-family: 'Geometria Regular';
			font-weight: 400;
			font-size: ms(3);
			color: $primary;
			margin-bottom: 20px;


			@include breakpoint($md) {
				font-size: ms(3)*.9;
			}
			@include breakpoint($xs) {
				font-size: ms(2);
			}
		}

		.date{
			color: $primary;
			font-size: ms(3);
			font-family: 'Geometria Regular';
			font-weight: 400;
		}

		@include breakpoint($md) {
			padding-bottom: 0;
		}
		@include breakpoint($xs) {
			padding-top: 15px;
			padding-bottom: 0;
		}
		@include breakpoint($xxs) {
			padding: 0;
		}

		.txt-introduction{
			@include breakpoint($xxs) {
				text-align: center;
			}

			.clearfix{
				padding: 0 0 20px 0;
			}

			.img-intro{
				img{
					width: 100%;
					@include breakpoint($xxs) {
						width: 35%;
						margin: auto;
					}
				}

				@include breakpoint($xxs) {
					width: 100%;
				}
			}

			.txt-intro{
				padding: 0 20px 30px 0;

				@include breakpoint($md) {
					padding: 0 10px 30px 0;
				}

				@include breakpoint($sm) {
					padding: 0 0 30px 0;
				}

				@include breakpoint($xxs) {
					width: 100%;
				}


				p{
					font-size: ms(3)*.99;
					line-height: ms(7)*.87;
					text-align: left;

					@include breakpoint($md) {
						text-align: left;
						font-size: ms(3)*.95;
					}
					@include breakpoint($xxs) {
						text-align: center;
					}
					
				}

				a{
					font-size: ms(3);
					color: $primary;
					float: right;
					font-family: 'Geometria Medium';
					font-weight: 400;
					letter-spacing: .5px;
					margin-top: 30px;

					@include breakpoint($md) {
						font-size: ms(2);
					}	
					@include breakpoint($xs) {
						float: left;
						margin-top: 15px;
					}

					&.bouton{
						background-image: url('../../img/input-form-arrow.png');
						background-image: url('../../img/input-form-arrow.svg');
						background-repeat: no-repeat;
						background-position: left center;
						background-size: 20px;
						padding-left: 30px;

						@include breakpoint($md) {
							background-size: 15px;
							padding-left: 25px;
						}
						@include breakpoint($xxs) {
							float: none;
							margin: 20px auto;
							display: table;
						}

						&.see-more {
							@include breakpoint($xs) {
								float: right;
							}
						}
					}
				}
			}

			&.txt-introduction-vote{

				padding: 0 0 20px;

				@include breakpoint($xs) {
					padding: 0 20px 20px;
				}

				.vote{

					float: right;

					@include breakpoint($xs) {
						float: left;
						clear: both;
						margin-top: 15px;
					}
					@include breakpoint($xxs) {
						float: none;
						margin-top: 15px;
						margin: 20px auto 0;
						display: inline-block;
					}

					img{
						width: 20px;
						position: relative;
						top: -5px;

						&:last-of-type{
							margin-right: 10px;
						}
					}
					font-family: 'Geometria Bold';
					font-weight: 700;
					font-size: ms(0);
					color: $base;

					span{
						font-family: 'Geometria Light';
						font-weight: 200;
						font-size: ms(7);	
						margin-left: 5px;
					}
				}

				.txt-introduction-left{
					float: left;

					@include breakpoint($xxs) {
						width: 100%;
					}
				}
			}
		}

		.bloc-txt-newsletter{
			.letter-bloc{
				padding: 20px;

				&.big{
					padding: 40px;

					@include breakpoint($xl) {
						padding: 50px;
					}	
					@include breakpoint($lg) {
						padding: 45px;
					}
					@include breakpoint($md) {
						padding: 25px;
					}
					@include breakpoint($sm) {
						padding: 20px;
					}

					.formulaire-lettre{

						@include breakpoint($xs) {
							margin-bottom: 0 !important;
						}
						
						input[type="submit"]{
							
							@include breakpoint($md) {
								margin-top: 20px;
							}
							@include breakpoint($sm) {
								margin-top: 10px;
								margin-bottom: 10px;
							}
							
						}
					}

				}

				.letter-icon{
					right: -25px;
					left: auto;
				}

				h4, h3{
					@include breakpoint($xxs) {
						margin-bottom: 0;
					}
				}

				.formulaire-lettre{
					margin: 15px 0;

					@include breakpoint($xs) {
						margin: 0 0 10px 0;
					}

					input{
						padding: 13px 20px;
					}

					input[type="submit"] {
						font-size: ms(2)*.9;
					}
				}
			}
		}
	}


	&.page-interieur{
		&.search{
			.content{
				padding-top: 30px;

				@include breakpoint($sm) {
					padding-top: 20px;
				}
			}
		}

		.content{
			padding-top: 0;
			padding-bottom: 0;

			.articles{
				margin: 0;
				padding: 35px 15px 20px 25px;

				@include breakpoint($md) {
					padding: 35px 15px 0 30px;
				}
				@include breakpoint($sm) {
					padding: 20px 20px 0 10px;
				}
				@include breakpoint($xs) {
					padding: 25px 0;
				}	

				&.lecture{
					.row{
						@include breakpoint($sm) {
							margin: 0;
						}
					}

					&.detail {
						padding-top: 0px !important;

						@include breakpoint($xxs) {
							padding-top: 20px !important;
						}

						.post-thumbnail {
							height: 350px;
							background-size: cover;
							background-position: center;
							margin-bottom: 30px;

							@include breakpoint($sm) {
								height: 250px;
							}
						}

						.bloc-introduction {

							@include breakpoint($lg) {
								padding-top: 10px;
							}

							.img-slider {
								margin: 5px 0 30px 0;
								width: 100%;

								@include breakpoint($lg) {
									width: 80%;
								}

								@include breakpoint($xs) {
									margin: 5px 0 30px 0;
									width: 100%;
								}
							}
						}
					}
				}

				.row{
					@include breakpoint($xs) {
						margin:0;
					}
				}



				.bloc-article{
					border-top: none;
					box-shadow: 0 0 20px #e2e2e2;

					@include breakpoint($xs) {
						padding: 0;
					}

					.txt-bloc-article{
						@include breakpoint($xs) {
							padding: 20px;
						}
					}

					.vote{
						img{
							width: 17px;
						}
						span{
							font-size: ms(4);
						}
					}
					.image-article{
						img{
							@include breakpoint($xs) {
								margin: 0;
							}
						}
					}
				}

				.bloc-introduction-articles{
					margin-bottom: 20px;

					@include breakpoint($xs) {
						padding: 0 15px;
					}

					.nav-order{
						text-align: left;
						padding-top: 40px;

						@include breakpoint($md) {
							padding-top: 25px;
						}
						@include breakpoint($sm) {
							padding-top: 15px;
						}

						ul{
							li{
								@include breakpoint($sm) {
									display: block;
								}

								&:first-child a{
									padding-left: 0;
								}

								a{
									padding: 0 10px;

									@include breakpoint($sm) {
										padding: 4px 0;
									}

									&:before{
										top: 0;

										@include breakpoint($sm) {
											content: "";
										}
									}
								}
							}
						}
					}

					h2{
						text-align: left;
						text-transform: none;
						font-family: 'Geometria Light';
						font-weight: 200;
						width: 60%;
						float: left;

						@include breakpoint($xxs) {
							width: 100%;
						}
					}

					.follow{
						float: right;
						width: 40%;
						text-align: right;

						@include breakpoint($xxs) {
							width: 100%;
							text-align: left;
							margin-top: 15px;
						}

						img{
							float: right;

							@include breakpoint($xxs) {
								float: left;
							}
						}

						h3{
							margin-bottom: 10px;
							font-family: 'Geometria Regular';
							font-weight: 400;
							font-size: ms(2);
						}
					}
				}

				.bloc{
					@include breakpoint($xs) {
						margin-bottom: 0;
					}
				}

				&.detail{

					@include breakpoint($xs) {
						padding: 20px;	
					}

					.bloc-introduction-articles{
						@include breakpoint($xs) {
							padding: 0;	
						}
					}

					.top-bar{

						a, h3{
							float: left;
							font-family: 'Geometria Bold';
							font-weight: 700;
							font-size: ms(0);
							color: $base;
						}

						.comm-lecture{
							float: left;

							a{
								margin-right: 15px;

								&:last-child{margin-right: 0;}

								font-family: 'Geometria Bold';
								font-weight: 700;
								font-size: ms(0);
								color: $base;

								@include breakpoint($md) {
									display: block;
									margin-bottom: 10px;
								}

								img{
									margin-right: 10px;
									width: 20px;
								}
							}
						}
						.print{
							float: left;

							i{
								color: $primary;
								font-size: ms(4);
								margin-right: 10px;
							}
						}

						.follow{
							float: right;

							@include breakpoint($md) {
								float: left;
							}

							h3{
								@include breakpoint($md) {
									float: left;
								}
								@include breakpoint($xs) {
									width: 100%;
									text-align: left;
								}
							}
							img{
								position: relative;
								top: -5px;

								@include breakpoint($md) {
									float: left;
								}
							}
						}
					}
					.bloc-img-content{
						width: 50%;
						float: left;
						margin: 0 20px 20px 0;

						@include breakpoint($xxs) {
							width: 100%;
							margin: 20px 0;
						}

						&.right-side{
							float: right;
							margin: 0 0 20px 20px;
							text-align: right;
							
						}

						img{
							width: 95%;
							@include breakpoint($sm) {
								width: 90%;
							}
							@include breakpoint($xxs) {
								width: 100%;
							}
						}
					}
					.bloc-txt-content{
						h2, h3{
							font-family: 'Geometria Regular';
							font-weight: 400;
							text-align: left; 
							text-transform: none;
							line-height: ms(7);

							@include breakpoint($md) {
								line-height: ms(6)*.9;
							}
						}

						h2{
							font-size: ms(5);

							@include breakpoint($md) {
								font-size: ms(5)*.95;
							}
							@include breakpoint($xs) {
								font-size: ms(5)*.9;
							}
						}
						h3{
							font-size: ms(4);

							@include breakpoint($md) {
								font-size: ms(4)*.95;
							}

							@include breakpoint($xs) {
								font-size: ms(4)*.9;
							}
						}

						blockquote{
							@include breakpoint($xs) {
								margin: 20px;
							}

							@include breakpoint($xxs) {
								margin: 10px;
							}	
						}

						p, ul{
							font-size: ms(3)*.98;
							line-height: ms(7)*.85;
							font-family: 'Geometria Light';
							font-weight: 200;
							margin: 8px 0;
							text-align: left;

							@include breakpoint($md) {
								line-height: ms(7)*.98;
								font-size: ms(3)*.86;
							}
							@include breakpoint($xs) {
								line-height: ms(7)*.96;
								font-size: ms(3)*.82;
							}
						}

						ul{
							
							padding-left: 50px;

							@include breakpoint($sm) {
								padding-left: 40px;
							}	
							@include breakpoint($xs) {
								padding-left: 0;
							}
							

							li{
								list-style-type: none;
								position: relative;

								&:before{
									content: "• ";
									color: $primary;
									position: absolute;
									left: -15px;

									@include breakpoint($xs) {
										left: -10px;
									}
								}
							}
						}

						a{
							color: $primary;
							text-decoration: none;
							font-size: inherit;
							font-family: 'Geometria Light';
							font-weight: 200;
							border-bottom: 1px solid $primary;

							&.bouton{
								background-image: url('../../img/input-form-arrow.png');
								background-image: url('../../img/input-form-arrow.svg');
								background-repeat: no-repeat;
								background-position: left center;
								background-size: 20px;
								font-size: ms(3);
								padding-left: 30px;
								border-bottom: none;

								@include breakpoint($md) {
									background-size: 15px;
									padding-left: 25px;
								}
								
							}
						}

						&.bloc-pers{
							background-color: #EFEFEF;
							padding: 50px;
							margin: 50px 0;

							@include breakpoint($md) {
								margin: 40px 0;
							}
							@include breakpoint($sm) {
								padding: 40px;
							}
							@include breakpoint($xs) {
								margin: 30px 0;
								padding: 25px;
							}	
							@include breakpoint($xxs) {
								margin: 90px 0 0px;
								padding: 20px;
							}

							.txt-pers{
								width: 80%;
								float: right;

								@include breakpoint($sm) {
									width: 75%;
								}
								@include breakpoint($xxs) {
									width: 100%;
								}
							}

							.img-pers{
								border-radius: 50%;
								float: left;
								width: 15%;

								@include breakpoint($sm) {
									width: 20%;
								}
								@include breakpoint($xxs) {
									width: 30%;
									margin: auto;
									display: table;
									float: none;
									margin-top: -80px;
								}
							}

							h3{
								font-family: 'Geometria Bold';
								font-weight: 700;
							}
							p{
								width: 90%;

								@include breakpoint($sm) {
									width: 100%;
								}
								@include breakpoint($xxs) {
									text-align: left;
								}
							}

							a{
								&.bouton{
									margin: 20px 0 0 0;
									display: block;
								}
							}

							h4{
								color: $primary;
								font-family: 'Geometria Regular';
								font-weight: 400;
								font-size: ms(3);
							}

						}
					}

					.bloc-end{
						margin-top: 40px;

						@include breakpoint($sm) {
							margin-top: 20px;
						}

						.vote{
							float: left;

							p{
								margin-bottom: 10px;
							}
							img{
								width: 25px;
								position: relative;
								top: -5px;

								&:last-of-type{
									margin-right: 10px;
								}
							}
							font-family: 'Geometria Bold';
							font-weight: 700;
							font-size: ms(1);
							color: $base;
						}

						.bouton{
                            background: $secondary;
                            color: #fff;
                            border-radius: 50px !important;
                            padding: 15px 20px 12px 20px;
                            float: right;
                            font-size: ms(2);

                            @include breakpoint($sm) {
                            	width: 100%;
                            	text-align: center;
                            	margin-top: 15px;
                            }

                            @include breakpoint($xs) {
                               border-radius: 0;
                               font-family: 'Geometria Medium';
                               font-weight: 400;
                            }

                            @include breakpoint($xxs) {
                            	padding: 10px;
                            	margin-top: 20px;
                            }	

                            &:hover{
                               color: #fff;
                               background: #c9dfee;
                            }

                            &.actif{
                               border-bottom: none;
                            }
                        }
					}
				}

				&.other{
					padding: 0;

					.bloc-introduction-articles{
						margin: 0;
					}

					p{
			
						height: 95px;

						@include breakpoint($md) {
							height: 110px;
						}
						@include breakpoint($sm) {
							height: 85px;
						}
						@include breakpoint($xs) {
							height: auto;
						}
					}

					h2{
						padding: 50px 0;
						text-align: left;
						text-transform: none;

						@include breakpoint($md) {
							padding: 40px 0;
						}
						@include breakpoint($sm) {
							width: 100%;
						}	
						@include breakpoint($xs) {
							padding: 20px 0;
						}
						@include breakpoint($xxs) {
							padding: 10px 0;
						}
					}
				}
			}

			&.no-sidebar{
				.articles{	
					padding: 50px 30px;

					@include breakpoint($md) {
						padding: 35px 0 0 45px;
					}
					@include breakpoint($sm) {
						padding: 20px 0 0 20px;
					}
					@include breakpoint($xs) {
						padding: 25px 0;
					}	

					.bloc{
						h3{
							text-transform: uppercase;
						}

						.comm-lecture{
							.article{
								i{
									margin-right: 5px;
									font-size: ms(2);
								}
							}
						}
					}
					.link-more{
						font-size: ms(4);
						text-align: center;
						display: inline-block;
						margin: 20px auto 0 auto;
						float: none;
						width: 100%;
						text-transform: uppercase;

						@include breakpoint($md) {
							margin: 10px 0 30px 0;
							font-size: ms(3);
						}
						@include breakpoint($xs) {
							margin: 20px 0 5px 0;
							font-size: ms(2)*.9;
						}
						

						i{
							color: $primary;
							font-size: ms(5);
							position: relative;
							top: 7px;
							margin-right: 10px;

							@include breakpoint($md) {
								font-size: ms(4);
								top: 4px;
							}
						}
					}
				}
			}

			&.commentaires{
				background: #f5f5f5;
				padding: 50px;

				@include breakpoint($sm) {
					padding: 40px;
				}
				@include breakpoint($xs) {
					padding: 30px;
				}
				@include breakpoint($xxs) {
					padding: 20px;
				}		

				.bloc-intro-commentaire{
					h3{
						position: relative;
						font-size: ms(6);
						font-family: 'Geometria Medium';
						font-weight: 400;
						padding-left: 55px;
						float: left;

						@include breakpoint($md) {
							font-size: ms(5);
						}
						@include breakpoint($sm) {
							font-size: ms(5)*.9;
							padding-left: 50px;
						}
						@include breakpoint($xs) {
							font-size: ms(4);
							padding-left: 35px;
						}

						span{
							font-family: 'Geometria Regular';
							font-weight: 400;
							font-size: ms(-3);
							font-style: italic;
						}

						&:before{
							content: "\f075";
						    font-family: FontAwesome;
						    font-style: normal;
						    font-weight: normal;
						    text-decoration: inherit;
						    position: absolute;
						    color: $primary;
						    left: 0;
						    top: -5px;
						    font-size: ms(4);

						    @include breakpoint($xs) {
						    	font-size: ms(4)*.85;
						    }
						}
					}
					.bouton{
                        background: $primary;
                        color: #fff;
                        border-radius: 50px;
                        padding: 15px 20px 12px 20px;
                        font-size: ms(2);
                        float: right;
                        height: auto;
                        cursor: pointer;

                        @include breakpoint($xxs) {
                        	width: 100%;
                        	text-align: center;
                        	margin-top: 15px;
                        }

                        &:hover{
                           background: #b5dc3d !important;
                        }
                    }
				}

				.bloc-commentaire{
					margin: 25px 0;

					@include breakpoint($xxs) {
						margin: 15px 0;
					}

					h4{
						font-family: 'Geometria Bold';
						font-weight: 700;
						font-size: ms(3);
					}
					.date{
						color: $primary;
						font-size: ms(2);
						font-family: 'Geometria Regular';
						font-weight: 400;
					}
					p{
						font-family: 'Geometria Light';
						font-weight: 200;
						font-size: ms(2);	
						line-height: ms(6);
						margin: 10px 0;
					}
					
				}
				.bouton{
					background: $tertiary;
                    color: #fff;
                    margin-top: 5px;
                    border-radius: 50px;
                    padding: 10px 20px 10px 20px;
                    font-size: ms(2);
                    float: left;

                    @include breakpoint($xxs) {
                    	padding: 10px 20px;
                    	width: 100%;
                    	text-align: center;
                    }

                    &.grey{
                    	background: #707070;
                    	padding: 15px 30px;
                    	&:hover{
                    		background: #707070;
                    	}
                    }

                    &:hover{
                       color: #fff;
                       background: #b5dc3d;
                    }
				}
			}
		}

		.bloc-simple{

			.txt-introduction{
				@include breakpoint($sm) {
					width: 100%;
				}
			}

			.txt-intro{
				.img-introduction{
					float:left; 
					width: 30%;
					margin: 0 20px 20px 0;

					@include breakpoint($xxs) {
						width: 50%;
						float: none;
					}
				}
				p{
					@include breakpoint($sm) {
						text-align: justify;
					}
				}
				h5{
					font-size: ms(5);
					font-family: 'Geometria Medium';
					font-weight: 400;
					color: $primary;
					text-transform: uppercase;
					padding-right: 170px;
					position: relative;

					@include breakpoint($xxs) {
						width: auto;
						text-align: left;
					}

					&:after{
						content: "";
					    position: absolute;
					    right: 0;
					    top: 0;
					    font-size: ms(6);

					    background: url("../../img/icon-inscription.png");
					    background: url("../../img/icon-inscription.svg");
					    background-position: center right;
					    background-repeat: no-repeat;
					    width: 50px;
					    height: 100%;
					    background-size: contain;

					    @include breakpoint($sm) {
					    	transform: rotate(90deg);
					    	-webkit-transform: rotate(90deg);
					    	-ms-transform: rotate(90deg);

					    	width: 40px;
					    }
					    @include breakpoint($xs) {
					    	top: 0;
					    	background-position: top;
					    	right: 20px;
					    }
					}
				}
			}

			.slider-lettre{
				background: none;

				@include breakpoint($sm) {
					width: 100%;
				}
				.row{
					background: none;
				}

				.letter-bloc{
					//background-image: url("../../img/back-repeat-primary.jpg");
					background: #e3e9c3; /* Old browsers */
					background: -moz-linear-gradient(top, #f1f2e7 45%, #e3e9c3 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, #f1f2e7 45%,#e3e9c3 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, #f1f2e7 45%,#e3e9c3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2e7', endColorstr='#e3e9c3',GradientType=0 );

					&.big{
						@include breakpoint($xl) {
							padding: 40px;
						}
						@include breakpoint($md) {
							padding: 35px;
						}
						@include breakpoint($sm) {
							padding: 30px;
						}
						@include breakpoint($xs) {
							padding: 25px;
						}
						@include breakpoint($xxs) {
							padding: 20px;
						}
					}
				}

				.follow{
					text-align: right;
					padding-top: 25px;

					@include breakpoint($sm) {
						padding-bottom: 25px;
						float: left;
						text-align: left;
					}
					@include breakpoint($xs) {
						padding: 20px;
					}

					h3{
						margin-bottom: 10px;
					}

					img{
						float: right;
					}
				}
			}
		}
	}
}

