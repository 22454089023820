.alm-btn-wrap{
	.more{
		font-size: ms(4);
		text-align: center;
		display: inline-block;
		margin: 20px auto 0 auto;
		float: none;
		width: 100%;
		text-transform: uppercase;
		background: none;
		border: none;
		position: relative;

		@include breakpoint($md) {
			margin: 10px 0 30px 0;
			font-size: ms(3);
		}
		@include breakpoint($xs) {
			margin: 20px 0;
			font-size: ms(2)*.9;
		}


		&:before{
		    content: "\f055";
		    font-family: FontAwesome;
		    font-style: normal;
		    font-weight: normal;
		    text-decoration: inherit;
		    color: $primary;
		    font-size: ms(5);
		    margin-right: 15px;

		    position: relative;
		    top: 5px;

		    @include breakpoint($xs) {
		    	margin-right: 7px;
		    }
		}
		

		i{
			color: $primary;
			font-size: ms(5);
			position: relative;
			top: 7px;
			margin-right: 10px;

			@include breakpoint($md) {
				font-size: ms(4);
				top: 4px;
			}
		}
	}
}