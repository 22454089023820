/*
----------------------------------------
https://github.com/modularscale/modularscale-sass
http://www.modularscale.com/?1&em&1.5&sass&table
-----------------------------------------
ms(10) : 57.665em
ms(9)  : 38.443em
ms(8)  : 25.629em
ms(7)  : 17.086em
ms(6)  : 11.391em
ms(5)  : 7.594em
ms(4)  : 5.063em
ms(3)  : 3.375em
ms(2)  : 2.25em
ms(1)  : 1.5em
ms(0)  : 1em
ms(-1) : 0.667em
ms(-2) : 0.444em
*/


$ms-base: 1em;
$ms-ratio: 1.125;