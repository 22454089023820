#GeneralWrapper {
	.slider-lettre{
		background: #e3e9c3; /* Old browsers */
		background: -moz-linear-gradient(top, #f1f2e7 45%, #e3e9c3 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #f1f2e7 45%,#e3e9c3 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #f1f2e7 45%,#e3e9c3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2e7', endColorstr='#e3e9c3',GradientType=0 );
		padding:0;

		@include breakpoint($sm) {
			background-image: none;
		}
		@include breakpoint($xs) {
			padding: 0;
		}

		&.slider-lettre-int{
			.row{
				margin: 0;
			}
		}

		.row{

			@include breakpoint($sm) {
				margin: 0;
			}
			@include breakpoint($xs) {
				margin-right: -15px;
				margin-left: -15px;
			}
		}

		.bloc-txt-newsletter{
			@include breakpoint($lg) {
				width: 45%;
				padding: 0;
			}

			@include breakpoint($sm) {
				width: 100%;
				background: #e3e9c3; /* Old browsers */
				background: -moz-linear-gradient(top, #f1f2e7 45%, #e3e9c3 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top, #f1f2e7 45%,#e3e9c3 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom, #f1f2e7 45%,#e3e9c3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2e7', endColorstr='#e3e9c3',GradientType=0 );
				margin-top: -100px;
				z-index: 10;
			}
			@include breakpoint($xs) {
				margin-top: 0;
			}
		}

		.bloc-image-slider{
			@include breakpoint($lg) {
				width: 55%;
			}
			@include breakpoint($sm) {
				width: 100%;
				padding: 0;
			}
		}

		.bloc-slider{
			position: relative;
			display: none;
			
			&:first-child, &.slick-current {
				display: block;
			}

			.cache-slider{
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,ffffff+100&0+0,1+100 */
				background: rgba(152, 193, 28, .9);
				display: block;
				position: absolute;
				z-index: 1;
				width: 100%;
				bottom: -5px;
				left: 0;
				height: 115px;
				opacity: 1;

				pointer-events: none;

				@include breakpoint($sm) {
					bottom: 100px;
				}

				@include breakpoint($xs) {
					bottom: -5px;
				}
			}

			.img-slider{
				width: 100%;
			}

			.txt-slider{
				position: absolute;
				bottom: auto;
				top: 430px;
				left: 30px;
				z-index: 2;
				width: 70%;

				@include breakpoint($lg) {
					top: 340px;
				}

				@include breakpoint($md) {
					top: 260px;
				}

				@include breakpoint($sm) {
					top: 305px;
				}

				@include breakpoint($xs) {
					top: auto;
					bottom: 15px;
				}
				@include breakpoint($xxs) {
					left: 35px;
					bottom: 20px;
				}

				h2, h3{
					font-weight:  700;
					letter-spacing: .5px;
				}

				h2{
					font-family: 'Geometria Extra Bold';
					font-size: ms(6)*.8;
					color: white;

					@include breakpoint($sm) {
						font-size: ms(5);
					}

					@include breakpoint($xs) {
						font-size: ms(5)*.9;
						height: 70px;
					}

					@include breakpoint($xxs) {
						font-size: ms(4);
					}
				}
				h3{
					font-family: 'Geometria Bold';
					color: $primary;
					font-size: ms(4);

					@include breakpoint($xs) {
						font-size: ms(3);
					}
				}
			}
		}

		&.slider-lettre-int{

			&.blue{
				.letter-bloc{
					background: url('../../img/back-repeat-secondary.jpg');

					.letter-bloc-left{
						h2{
							big{
								color: #6a9c9f;
								font-size: ms(5);
							}
						}
						h3{
							color: #6a9c9f;
						}
					}
					.letter-bloc-right{
						form{
							input{
								background: #6a9c9f;
							}
						}
					}
				}
			}


			.row{
				@include breakpoint($xs) {
					margin: 0;
					background: url("../../img/back-repeat-primary.jpg");
				}	
			}

			.letter-bloc-left{

				@include breakpoint($sm) {
					width: 100%;
				}
			}

			.letter-bloc-right{
				@include breakpoint($sm) {
					width: 100%;
				}
			}

			.bloc-txt-newsletter{
				@include breakpoint($lg) {
					width: 55%;
					padding: 0;
				}
				@include breakpoint($md) {
					width: 62%;
				}

				@include breakpoint($sm) {
					width: 100%;
					background: #e3e9c3; /* Old browsers */
					background: -moz-linear-gradient(top, #f1f2e7 45%, #e3e9c3 100%); /* FF3.6-15 */
					background: -webkit-linear-gradient(top, #f1f2e7 45%,#e3e9c3 100%); /* Chrome10-25,Safari5.1-6 */
					background: linear-gradient(to bottom, #f1f2e7 45%,#e3e9c3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2e7', endColorstr='#e3e9c3',GradientType=0 );
					margin-top: 0;
					z-index: 10;
				}
				@include breakpoint($xs) {
					margin-top: 0;
					width: 72%;
				}
				@include breakpoint($xxs) {
					width: 100%;
				}

				.letter-icon{
					@include breakpoint($sm) {
						opacity: .05;
					}
				}
			}

			.bloc-image-slider{
				@include breakpoint($lg) {
					width: 38%;
				}
				@include breakpoint($md) {
					width: 38%;
				}
				@include breakpoint($sm) {
					width: 100%;
					padding: 0;
				}
				@include breakpoint($xs) {
					width: 28%;
				}
				@include breakpoint($xxs) {
					display: none;
				}


				.img-slider{
					@include breakpoint($sm) {
						display: none;
					}
					@include breakpoint($xs) {
						display: block;
					}
				}
			}


			input{
				@include breakpoint($lg) {
					width: 100%;

					&:focus{
						width: 100%;
					}
				}
			}
			input[type="submit"]{
				@include breakpoint($md) {
					margin-top: 10px;
				}
			}

			.formulaire-lettre{
				@include breakpoint($md) {
					margin: 10px 0 !important;	
				}
			}
		}

		&.slider-lettre-detail{
			padding: 0;

			.bloc-txt-newsletter{
				margin-top: 0;

				.big{
					@include breakpoint($xs) {
						padding: 20px 30px;
					}
				}
			}

			.row{
				@include breakpoint($xs) {
					margin:0;
				}
			}
		}
	}
}


