#GeneralWrapper {

  // MAIN NAV
    nav{
        &.nav{
            clear: both;
            width: 100%;
            display: table;
            text-align: center;
            margin-top: 0;
            position: relative;

            @include breakpoint($xs) {
                display: none;
            }

            ul{
                display: table-row;

                @include breakpoint($xs) {
                    display: block;
                }

                li{
                    list-style-type: none;
                    display: table-cell;
                    vertical-align: middle;
                    

                    @include breakpoint($xs) {
                        display: block;
                    }

                    &:first-child a, &:last-child a{
                        &:before{
                            content: "";
                        }
                    }

                    &.bouton{
                        &:before{
                            content: "";
                        }

                        &:hover{
                            a{
                                color: #56565a;
                                background: #e0e6c1;
                                border-radius: 30px 30px 0 0;
                            }
                        }

                        a{
                             background: #e3e9c3;
                             color: #56565a !important;
                             border-radius: 50px;
                             padding: 15px 15px 12px 15px;

                             @include breakpoint($xs) {
                                border-radius: 0;
                             }

                             &:hover{
                                color: #fff;
                                background: #e0e6c1;
                                border-radius: 30px 30px 0 0;

                                @include breakpoint($xs) {
                                    border-radius: 0;
                                }
                             }

                             &.actif{
                                border-bottom: none;
                             }
                        }

                        .second-nav{
                            width: auto;
                            right: 0;
                            left: auto;
                            border-bottom: 8px solid #e3e9c3;

                            -webkit-column-count: 1;
                            -moz-column-count: 1;
                            column-count: 1;
                            -webkit-column-gap: none;
                            -moz-column-gap: none;
                            column-gap: none;
                            padding-top: 0;
                            padding-bottom: 0;

                            a{
                                background: none;
                                color: $base !important;
                            }
                        }
                    }

                    .second-nav{
                        display: none;

                        box-shadow: 0 5px 10px #a9a9a9;

                        width: 100%;
                        position: absolute;
                        z-index: 101;
                        top: 59px;
                        left: 0;
                        background-color: #fff;

                        -webkit-column-count: 4;
                        -moz-column-count: 4;
                        column-count: 4;
                        -webkit-column-gap: 32px;
                        -moz-column-gap: 32px;
                        column-gap: 32px;
                        min-height: initial !important;

                        -webkit-column-rule: 1px outset #f1f1f1; /* Chrome, Safari, Opera */
                        -moz-column-rule: 1px outset #f1f1f1; /* Firefox */
                        column-rule: 1px outset #f1f1f1;

                        border-bottom: 8px solid $primary;
                        padding: 20px 10px;

                       

                        @include breakpoint($lg) {
                            top: 53px;
                        }   
                        @include breakpoint($sm) {
                            top: 49px;
                        }   

                        @include breakpoint($xs) {
                            display: none;
                            box-shadow: none;
                            top: 0;

                            -webkit-column-count: 1;
                            -moz-column-count: 1;
                            column-count: 1;
                            -webkit-column-gap: none;
                            -moz-column-gap: none;
                            column-gap: none;
                            position: relative;
                            padding-top: 0;
                            padding-bottom: 0;
                        }


                        li{
                            display: table;
                            position: relative;
                            -webkit-column-break-inside: avoid;
                            break-inside: avoid;

                            a{
                                display: block;
                                text-align: left;
                                font-size: ms(0);
                                padding: 15px;
                                border-bottom: none;

                                @include breakpoint($sm) {
                                    padding: 10px;
                                }   

                                &.actif{
                                    border: none;
                                    color: $primary;

                                    @include breakpoint($xs) {
                                        color: $primary !important;
                                        background: none !important;
                                    }   
                                }

                                &:before{
                                    content: "";
                                }
                            }
                        }
                    }

                    &:hover{
                        .second-nav{
                            display: block;

                            @include breakpoint($xs) {
                                display: none;
                            }
                        }
                    }

                    a{
                        text-decoration: none;
                        text-transform: uppercase;
                        font-family: 'Geometria Medium';
                        font-weight:  400;
                        color: $base;
                        padding: 20px 20px 18px 20px;
                        display: block;
                        font-size: ms(1);
                        letter-spacing: .3px;
                        position: relative;
                        border-bottom: 5px solid transparent;

                        -webkit-transition: all .6s ease;
                        -moz-transition: all .6s ease;
                        -ms-transition: all .6s ease;
                        -o-transition: all .6s ease;
                        transition: all .6s ease;

                        @include breakpoint($lg) {
                           padding: 15px 10px 18px 10px;
                        }

                        @include breakpoint($md) {
                            letter-spacing: 0;
                            padding: 20px 10px;
                        }

                        @include breakpoint($sm) {
                            padding: 15px 8px;
                            font-size: ms(1)*.95;
                        }   

                        @include breakpoint($xs) {
                            border-bottom: 1px solid #f1f1f1;
                        }


                        &:before{
                            content: "|";
                            position: absolute;
                            left: 0;
                            top: 20px;
                            color: #ccc;

                            @include breakpoint($xs) {
                                content: "";
                            }
                        }


                        i{
                            font-size: ms(3);
                        }


                        img{
                            width: 20px;
                        }

                        &:hover{
                            color: $primary;
                        }

                        &.actif{
                            border-bottom: 5px solid $primary;
                            color: $primary;
                           
                            @include breakpoint($xs) {
                                border-bottom: 2px solid $primary;
                                background: $primary;
                                color: #fff;

                                i{
                                    font-size: ms(4);
                                }
                            }
                        }
                    }

                    &.first-nav{
                        &:hover{
                            a.first-nav{
                                color: $primary;


                                &.actif{

                                    @include breakpoint($xs) {
                                        color: #fff !important;
                                    }
                                    &:hover{
                                        @include breakpoint($xs) {
                                            color: #fff !important;
                                        }

                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.main-nav{


            &.actif{
                margin-bottom: 30px;
            }
        }

        &.nav-order{
            display: block;

            @include breakpoint($xs) {
                display: none;
                width: 100%;
            }

            ul{
                margin: auto;
                display: inline-block;

                @include breakpoint($xs) {
                    display: block;
                    width: 100%;
                }

                li{
                    &:before{
                        content: "";
                    }

                    &:last-child a{
                        &:before{
                            content: "|";
                        }
                    }

                    a{
                        text-transform: inherit;
                        font-size: ms(1);
                        border-bottom: none;

                        @include breakpoint($lg) {
                            &:before{
                                top: 15px;
                                left: -2px;
                            }
                        }
                        @include breakpoint($md) {
                            &:before{
                                top: 20px;
                            }
                        }
                        @include breakpoint($sm) {
                            &:before{
                                top: 15px;
                            }
                        }

                        &.actif{
                            color: $primary;
                            border-radius: 50px;
                            border-bottom: none;

                            

                            @include breakpoint($xs) {
                                border-radius: 0;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }

        &.footer-nav{
            display: block;
            float: left;

            ul{
                li{
                    a{
                        
                        &.actif{
                            border-top: 5px solid $primary;
                            border-bottom: none;
                        }
                    }
                }
            }
        }

        &.footer-min-nav{

            margin-top: 50px;
            ul{
                text-align: right;
                li{
                    list-style-type: none;
                    display: inline-block;

                    &:after{
                        content: "•";
                    }

                    &:last-child{
                        &:after{
                            content: "";
                        }
                    }

                    a{
                        padding: 0 5px;
                        font-family: 'Geometria Regular';
                        font-weight:  400;
                        text-decoration: none;
                        color: $base;

                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }

        &.breadcrumb{

            position: absolute;
            bottom: 10px;

            ul{
                li{
                    list-style-type: none;
                    display: inline-block;

                    &:after{
                        
                        content: "\f105";
                        font-family: FontAwesome;
                        font-style: normal;
                        font-weight: normal;
                        text-decoration: inherit;
                    }

                    &:last-of-type{
                        &:after{
                            content: "";
                        }
                    }

                    a{
                        text-decoration: none;
                        color: $base;
                        font-family: 'Geometria Medium';
                        font-weight: 400;
                        font-size: ms(1);
                        padding: 0 10px;

                        @include breakpoint($md) {
                            padding: 0 5px;
                        }

                    }

                    &:last-child a{
                        color: $primary;
                    }
                    &:first-child a{
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .left-sidebar{
        text-align: left;
        background-color: #f4f4f4;
        padding: 0;
        //background-image: url('../../img/backrepeat-left-sidebar.jpg');
        z-index: 100;
        position: relative;
        width: 27%;


        @include breakpoint($xs) {
            width: 100%;
            display: none;
        }

        .nav-left-sidebar{
            padding: 30px 40px;

            @include breakpoint($md) {
                padding: 25px;
            }
        }

        .bloc-txt-newsletter {
            .letter-bloc{
            padding: 35px 40px;
            background: rgba(0, 0, 0, .01);

            @include breakpoint($md) {
                padding: 25px;
            }

            .letter-icon{
                left: -25px;

                @include breakpoint($md) {
                    right: -15px;
                    width: 35px;
                }
                @include breakpoint($sm) {
                    display: none;
                }
            }

            .formulaire-lettre{
                margin-bottom: 0;

                input{
                    padding: 10px 20px;

                    @include breakpoint($md) {
                        width: 100%;
                    }
                }
                input[type="submit"]{
                    margin-top: 15px;
                }
            }
            .letter-bloc-left{
                @include breakpoint($sm) {
                    width: 100%;
                }
                h2{
                    text-transform: none;
                    font-size: ms(3);
                    font-family: 'Geometria Medium';
                    font-weight: 400;
                }
            }
            .letter-bloc-right{
                @include breakpoint($sm) {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }

        }

        h2{
            text-align: left;  
        }

        hr{
            width: 20px;
            border-color: $primary;
            display: inline-block;
            margin: 10px 0;
            border: 2px solid $primary;
        }

        .nav-sidebar{
            ul{
                li{
                    list-style-type: none;

                    &.see-more{
                        a{
                            color: $primary;
                            font-family: 'Geometria Bold';
                            font-weight: 700;
                            position: relative;

                            &:after{
                                content: "\f0d7";
                                font-family: FontAwesome;
                                font-style: normal;
                                font-weight: normal;
                                text-decoration: inherit;
                                margin-left: 10px;
                                -ms-transform: rotate(0);
                                -webkit-transform: rotate(0); 
                                transform: rotate(0);
                                position: absolute;
                                right: -20px;
                                top: -5px;

                                -webkit-transition: all .6s ease;
                                -moz-transition: all .6s ease;
                                -ms-transition: all .6s ease;
                                -o-transition: all .6s ease;
                                transition: all .6s ease;
                           
                            }

                            &.actif{
                                &:after{
                                    -ms-transform: rotate(180deg);
                                    -webkit-transform: rotate(180deg); 
                                    transform: rotate(180deg);
                                }
                            }
                        }  
                    }

                    a{
                        font-family: 'Geometria Regular';
                        font-weight: 400;
                        font-size: ms(2) * .8;
                        text-transform: uppercase;
                        line-height: ms(5);
                        color: $base;

                        -webkit-transition: color .6s ease;
                        -moz-transition: color .6s ease;
                        -ms-transition: color .6s ease;
                        -o-transition: color .6s ease;
                        transition: color .6s ease;

                        &:hover{
                            color: $primary;
                        }
                        &.actif{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .nav-footer-min{
        @include breakpoint($xs) {
            height: auto;
        }
        @include breakpoint($xxs) {
            height: auto;
            width: 50%;
            padding: 0;
        }

        &.actif{
            width: 100%;
            height: auto;
        }

        nav{
            ul{
                li{
                    list-style-type: none;

                    &.see-more{
                        a{
                            color: $primary;
                            font-family: 'Geometria Bold';
                            font-weight: 700;
                            position: relative;

                            &:after{
                                content: "\f0d7";
                                font-family: FontAwesome;
                                font-style: normal;
                                font-weight: normal;
                                text-decoration: inherit;
                                margin-left: 10px;
                                -ms-transform: rotate(0);
                                -webkit-transform: rotate(0); 
                                transform: rotate(0);
                                position: absolute;
                                right: -20px;
                                top: -5px;

                                -webkit-transition: all .6s ease;
                                -moz-transition: all .6s ease;
                                -ms-transition: all .6s ease;
                                -o-transition: all .6s ease;
                                transition: all .6s ease;
                           
                            }

                            &.actif{
                                color: $primary;
                                
                                &:after{
                                    -ms-transform: rotate(180deg);
                                    -webkit-transform: rotate(180deg); 
                                    transform: rotate(180deg);
                                }
                            }
                        }  
                    }

                    a{
                        font-family: 'Geometria Regular';
                        font-weight: 400;
                        font-size: ms(1);
                        line-height: ms(5);
                        color: $base;

                        -webkit-transition: color .6s ease;
                        -moz-transition: color .6s ease;
                        -ms-transition: color .6s ease;
                        -o-transition: color .6s ease;
                        transition: color .6s ease;

                        &:hover{
                            color: $primary;
                        }
                        &.actif{
                            color: $primary;
                        }
                    }
                }
            }
        }

    }

    .btn-filtre{
        color: #fff;
        margin: auto;
        display: none;
        background: $primary;
        padding: 10px 20px;
        border-radius: 20px;
        margin-top: 10px;

        -webkit-transition: all .6s ease;
        -moz-transition: all .6s ease;
        -ms-transition: all .6s ease;
        -o-transition: all .6s ease;
        transition: all .6s ease;


        &.actif{
            margin-bottom: 15px;
        }

        @include breakpoint($xs) {
            display: table;
        }

    }
}








