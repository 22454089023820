
@font-face {
  font-family: 'Geometria Thin';
  src: url('../../dist/fonts/geometria/geometriaThin/Geometria-Thin.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaThin/Geometria-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaThin/Geometria-Thin.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaThin/Geometria-Thin.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaThin/Geometria-Thin.svg#54424b71809af96d4167773f84feb00e') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  200;
}

@font-face {
  font-family: 'Geometria Light';
  //src: url('../../dist/fonts/geometria/geometriaLight/Geometria-Light.eot'); /* IE9 Compat Modes */
  //src: url('../../dist/fonts/geometria/geometriaLight/Geometria-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //     url('../../dist/fonts/geometria/geometriaLight/Geometria-Light.woff') format('woff'), /* Modern Browsers */
  //     url('../../dist/fonts/geometria/geometriaLight/Geometria-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
  //     url('../../dist/fonts/geometria/geometriaLight/Geometria-Light.svg#1da9155179c3a05002030dfcf32951dc') format('svg'); /* Legacy iOS */
  //     
  //font-style:   normal;
  //font-weight:  200;

  src: url('../../dist/fonts/geometria/geometriaRegular/Geometria.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaRegular/Geometria.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.svg#f2b78fad4e5f65c9a818dcb27caad571') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}


@font-face {
  font-family: 'Geometria Regular';
  src: url('../../dist/fonts/geometria/geometriaRegular/Geometria.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaRegular/Geometria.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaRegular/Geometria.svg#f2b78fad4e5f65c9a818dcb27caad571') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}


@font-face {
  font-family: 'Geometria Medium';
  src: url('../../dist/fonts/geometria/geometriaMedium/Geometria-Medium.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaMedium/Geometria-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaMedium/Geometria-Medium.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaMedium/Geometria-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaMedium/Geometria-Medium.svg#f9cda347a4eda12bb7238467a46c99a1') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Geometria Bold';
  src: url('../../dist/fonts/geometria/geometriaBold/Geometria-Bold.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaBold/Geometria-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaBold/Geometria-Bold.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaBold/Geometria-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaBold/Geometria-Bold.svg#65303fca5f0ae009ce8017f6e4b94d00') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}


@font-face {
  font-family: 'Geometria Extra Bold';
  src: url('../../dist/fonts/geometria/geometriaExtraBold/Geometria-ExtaBlack.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/geometria/geometriaExtraBold/Geometria-ExtaBlack.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/geometria/geometriaExtraBold/Geometria-ExtaBlack.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/geometria/geometriaExtraBold/Geometria-ExtaBlack.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/geometria/geometriaExtraBold/Geometria-ExtaBlack.svg#b5670e716ce5ac83569b8f3728f87d24') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}


@font-face {
  font-family: 'Alegreya';
  src: url('../../dist/fonts/alegreya/alegreyaRegular/Alegreya-Regular.eot'); /* IE9 Compat Modes */
  src: url('../../dist/fonts/alegreya/alegreyaRegular/Alegreya-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../dist/fonts/alegreya/alegreyaRegular/Alegreya-Regular.woff') format('woff'), /* Modern Browsers */
       url('../../dist/fonts/alegreya/alegreyaRegular/Alegreya-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../../dist/fonts/alegreya/alegreyaRegular/Alegreya-Regular.svg#5131768f368212e950e75a15a2d2ce51') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}



