#GeneralWrapper {
  header {

    @include breakpoint($sm) {
        padding: 0;
    }
    @include breakpoint($xxs) {
        padding: 0 10px;
    }

    .menu-mobile-icon{
        position: absolute;
        color: $base;
        font-size: ms(11);
        display: none;
        right: 40px;
        top: 40px;
        cursor: pointer;

        @include breakpoint($xs) {
            display: block;
        }
        @include breakpoint($xxs) {
            font-size: ms(9);
        }
    }

    .row{
        @include breakpoint($sm) {
            margin: 0;
        }   
        @include breakpoint($xs) {
            margin-right: -15px;
            margin-left: -15px;
        }
    }

    .logo{
        margin-top: 30px;
        float: left;
        margin-left: -10px;

        @include breakpoint($xs) {
            margin-left: 30px;
            margin-top: 15px;
        }

        img{
            width: 248px;

            @include breakpoint($md) {
                width: 230px;
            }
            @include breakpoint($xs) {
                width: 200px;
            }

            @include breakpoint($xxs) {
                width: 160px;
            }
        }
    }

    .link-header-search{
        margin-top: -10px;
        position: relative;
        z-index: 5;

        @include breakpoint($xs) {
            width: 100%;
            display: block;
        }

        .link-header{
            font-family: 'Geometria Bold';
            font-weight: 700;
            font-size: ms(0);
            text-transform: uppercase;
            letter-spacing: .5px;
            display: table-cell;
            margin-left: 20px;
            position: relative;
            padding-left: 20px;
            text-decoration: none;
            color: $base;

            @include breakpoint($xs) {
                display: none;
            }

            span{
                padding-top: 25px;
                float: right;
            }
            div{
                background-color: $primary;
                background-image: url('../../img/espace-perso.png');
                background-image: url('../../img/espace-perso.svg');
                background-position: center bottom 15px;
                background-repeat: no-repeat;
                background-size: 20px;
                width: 50px;
                height: 55px;
                float: right;
                margin-left: 15px;
                border-radius: 0 0 10px 10px;

                -webkit-transition: height .6s ease;
                -moz-transition: height .6s ease;
                -ms-transition: height .6s ease;
                -o-transition: height .6s ease;
                transition: height .6s ease;

                &.contact-link{
                    background-color: $base;
                    background-image: url('../../img/nous-contacter-icon.png');
                    background-image: url('../../img/nous-contacter-icon.svg');
                    background-size: 25px;
                }
            }
        }
    }

    .search-bar{
        float: right;
        margin-top: 35px;
        position: relative;
        width: 100%;
        text-align: right;

        @include breakpoint($sm) {
            width: 75%;
        }

        @include breakpoint($xs) {
            margin: 20px auto;
            float: none;
            width: 100%;
        }

        i{
            cursor: pointer;
            font-size: ms(2);
            margin-right: 10px;
            position: absolute;
            z-index: 10;

            transform: rotate(0);
            -webkit-transform: rotate(0);
            -ms-transform: rotate(0);

            top: 17px;

            -webkit-transition: all .6s ease;
            -moz-transition: all .6s ease;
            -ms-transition: all .6s ease;
            -o-transition: all .6s ease;
            transition: all .6s ease;

            opacity: 0;
            left: 50px;

            @include breakpoint($md) {
                position: absolute;
                top: 17px;
            }


            @include breakpoint($xs) {
               display: none;
            }

            &.actif{
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                opacity: 1;
                left: -35px;

                @include breakpoint($md) {
                    left: 50px;
                }

                @include breakpoint($sm) {
                    left: -30px;
                }

            }
        }

        input{
            background-image: url('../../img/search-icon.png');
            background-image: url('../../img/search-icon.svg');
            background-repeat: no-repeat;
            background-position: right 20px center;
            background-size: 20px;
            background-color: rgba(255, 255, 255, 0.1);
            width: 330px;
            padding: 15px 60px 15px 20px;
            font-size: ms(1);
            box-shadow: none;
            border: 1px solid #c8c8c8;
            border-radius: 50px;

            -webkit-transition: all .6s ease;
            -moz-transition: all .6s ease;
            -ms-transition: all .6s ease;
            -o-transition: all .6s ease;
            transition: all .6s ease;

            @include breakpoint($sm) {
                width: 100%;
            }

            @include breakpoint($xs) {
                width: 90%;
                margin: auto;
                float: none;
                display: block;
            }

            &:focus {
              width: 100%;
              border-color: $primary;

              @include breakpoint($md) {
                width: 330px;
              }

              @include breakpoint($sm) {
                width: 100%;
              }
              
              @include breakpoint($xs) {
                border-radius: 0;
                border-left: none;
                border-right: none;
              }
             
            }
        }  
        input[type="submit"] {
            border: none;
            background-color: $primary;
            width: 40px;
            height: 40px;
            z-index: 5;
            position: absolute;
            top: 5px;
            right: 5px;
            overflow: hidden;
            padding: 0;

            display: none;
            -webkit-transition: none;
            -moz-transition: none;
            -ms-transition: none;
            -o-transition: none;
            transition: none;

            background-image: url('../../img/search-icon-white.png');
            background-image: url('../../img/search-icon-white.svg');
            background-position: center;
            background-size: 15px;
            background-repeat: no-repeat;


            @include breakpoint($lg) {
                width: 38px;
                height: 38px;
            }

            @include breakpoint($xs) {
                height: 48px;
                width: 48px;
                border-radius: 0;
                top: 0;
                right: 0;
            }

            &:hover{
                background-color: #b5dc3d;
                background-size: 18px;
            }
        }    
    }
  }
}