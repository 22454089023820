

$base : #56565a;

$primary: #98c11c;
$secondary: #b7cfe0;
$tertiary: #cbcbcb;


.primary{
	color: $primary;
}