body {
	//background: url(../../img/back-repeat.jpg);
	//background-repeat: repeat;

  	#GeneralWrapper{
	  	font-family: 'Geometria Light';
	  	font-weight:  200;
	  	color: $base;

	  	h1, h2, h3, h4, p, ul{
	  		padding: 0;
	  		margin: 0;
	  	}

	  	p{
	  		line-height: ms(4)*.92;
	  	}
	  	b, strong{
			font-family: 'Geometria Medium' !important;
			font-weight: 400 !important;
		}
  	}
}



a {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

a:hover,
a:focus {
   outline: none;
}
