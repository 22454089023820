#GeneralWrapper {
	form{
		&.formulaire-lettre{
			margin: 25px 0;

			@include breakpoint($lg) {
				margin: 10px 0;
			}
			@include breakpoint($md) {
				margin: 20px 0;
			}

			@include breakpoint($sm) {
				width: 100%;
				float: left;
				margin: 0 0 20px 0;
			}
			@include breakpoint($xxs) {
				margin: 0 0 10px 0;
			}

			br{
				display: none;
			}

			input{
				display: block;
				margin-left: auto;
				margin-right: auto;
	            background-repeat: no-repeat;
	            background-position: right 20px center;
	            background-size: 20px;
	            background-color: #fff;
	            width: 90%;
	            padding: 15px 20px;
	            font-size: ms(1);
	            letter-spacing: .4px;
	            box-shadow: none;
	            border: 1px solid #c8c8c8;
	            border-radius: 50px;

	            -webkit-transition: all .6s ease;
	            -moz-transition: all .6s ease;
	            -ms-transition: all .6s ease;
	            -o-transition: all .6s ease;
	            transition: all .6s ease;

	            @include breakpoint($lg) {
	            	padding: 15px 20px;
	            	display: block;
	            }
	            @include breakpoint($md) {
	            	padding: 10px 60px 10px 14px;
	            }
	            @include breakpoint($xs) {
	            	width: 100%;
	            }

	            @include breakpoint($xxs) {
	            	width: 70%;
	            }

	            &:focus {
	              background-position: right -50px center;
	              width: 90%;
	              border-color: $primary;

	              @include breakpoint($lg) {
	              	width: 60%;
	              }
	              @include breakpoint($xs) {
	              	width: 100%;
	              }
	              @include breakpoint($xxs) {
	              	width: 70%;
	              }
	            }
			}

			input[type="submit"] {
				display: block;
				text-transform: uppercase;
				color: #fff;
				background: $primary;
				width: auto;
				padding: 15px 35px;
				margin-top: 15px;
				border: none;
				font-family: 'Geometria Bold';
				font-weight: 700;
				font-size: ms(2);
				letter-spacing: .5px;


				&:hover{
					background: #b5dc3d;
				}
			}

			&.formulaire-letter-min{
				margin: 25px 0 0;

				@include breakpoint($xs) {
					margin: 15px 0 0;
				}

				input{
					float: left;
					width: auto;
					padding-right: 120px;

					@include breakpoint($lg) {
						padding-right: 100px;
						width: 290px;
					}
					@include breakpoint($sm) {
						width: 270px;
						padding-right: 115px;
					}
					@include breakpoint($xs) {
						width: 90%;
					}	

					&:focus {
						width: auto;

						@include breakpoint($sm) {
							width: 270px;
						}
						@include breakpoint($xs) {
							width: 90%;
						}	
					}

					&:last-of-type{
						margin: 0;
						width: auto;
						margin-left: -100px;
						padding:15px 20px;

						@include breakpoint($lg) {
			            	padding: 11px 20px;
			            }

			            @include breakpoint($xs) {
			            	margin-left: 0;
			            	margin-top: 15px;
			            }
					}
				}
			}
		}
	}

	.wc-form-wrapper{
		.wpd-form-row{
			.wc_comm_submit{
				background: $primary;
				padding: 10px 20px;
				text-align: center;
				color: #fff;
				border-radius: 50px;
				border: none;
				font-size: ms(1);
			}
		}
	}


	#comments{

		#wc-comment-header{
			font-family: 'Geometria Regular';
			font-weight: 400;
			margin-top: 20px;


		}
		.wc-comment-author, .wc-comment-text, .wc-comment-footer{
			padding-left: 0;


		}
		.wc-comment-author{
			font-family: 'Geometria Bold';
			font-weight: 700;
			color: $primary;

			@include breakpoint($xxs) {
				width: 80%;
			}

			a{
				font-family: 'Geometria Bold';
				font-weight: 700;
				color: $primary;
			}
		}
		.wc-comment-img-link{
			border-radius: 50%;
		}
		.wc-reply-button{
			margin-left: 0;

			&.wc-cta-active{
				background: $primary !important;
				border: none !important;
			}
		}
		#wpcomm{
			width: 100%;
			max-width: 100%;
			.wc-comment-title{
				font-family: 'Geometria Regular';
				font-weight: 400;
			}
		}
		.wc-reply{
			@include breakpoint($xxs) {
				margin-left: 15px !important;
			}
		}

		.wpdiscuz-readmore{
			color: $primary !important;

		}

		.wc-comment-link{
			.wc-comment-link-input{
				color: #56565a;
				background: none;
				color: #fff;
				border: 1px solid #fff;
				padding: 5px 10px;
				a{
					color: $base;
				}
			}
		}
		#wpcomm .wpf-cta:hover{
			background: $primary !important;
			border: none !important;
		}
		.wc-comment-footer{
			border: none;

			@include breakpoint($xs) {
				margin-bottom: 10px;
			}

			#wpcomm .wc-cta-active{
				display: none;
			}
			.wc-cta-button, .wc-cta-active{
				i{
					display: none;
				}
				background: #cbcbcb;
				color: #fff;
				margin-top: 5px;
				border-radius: 50px;
				padding: 7px 15px 7px 15px;
				font-size: ms(1);
				height: auto;
				border:none;
				text-transform: none;

				@include breakpoint($xs) {
					padding: 5px 15px 5px 15px;
				}	


				&:hover{
					background: $primary !important;
					border: none !important;
					height: auto !important;
					line-height: auto !important;
				}

				&.wc_editable_comment{
					background: #d03f58 !important;
					color: #fff !important;
					margin-left: 10px;
				}
			}
			.wc-cta-button-x{
				background: #d03f58;
				color: #fff;
				margin-top: 5px;
				border-radius: 50px;
				padding: 7px 15px 7px 15px;
				font-size: ms(1);
				height: auto;
				border:none;
				text-transform: none;
				margin-left: 10px;
			}
		}

		#wc_show_hide_loggedin_username{
			width: 100%;
			max-width: 100%;
		}
		.wc-comment-left{
			display: none;
		}
		.wc-comment-right {
			width: 100%;
			margin-left: 0;
			padding: 0;
			background: none;

			.wc_edit_comment{
				max-width: 100%;
				padding: 10px;
				border: 1px solid #ccc;
				box-shadow: none !important;
			}
			.wc_save_edited_comment{
				background: $primary;
				padding: 5px 15px;
				text-align: center;
				color: #fff;
				border-radius: 50px;
				border: none;
				font-size: ms(1);
			}
		}
		.wc-field-avatararea{
			display: none;
		}
		.wc-field-textarea{
			width: 100%;
			max-width: 100%;
			margin-left: 0;
		}

	}
}