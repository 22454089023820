#GeneralWrapper {
	.txt-lecteur{

		position: initial;

		.slick-slide{
			outline: none;
		}

		.slick-prev,
		.slick-next{
		    font-size: 0;
		    line-height: 0;

		    position: absolute;
		    top: 50%;

		    display: block;

		    width: 50px;
		    height: 68px;
		    padding: 0;
		    -webkit-transform: translate(0, -50%);
		    -ms-transform: translate(0, -50%);
		    transform: translate(0, -50%);

		    cursor: pointer;

		    color: transparent;
		    border: none;
		    outline: none;
		    background: transparent;

		    -webkit-transition: opacity .6s ease;
            -moz-transition: opacity .6s ease;
            -ms-transition: opacity .6s ease;
            -o-transition: opacity .6s ease;
            transition: opacity .6s ease;

            @include breakpoint($lg) {
            	width: 37px;
				height: 50px;
				display: none !important;
            }
           

		    &:hover{
		    	opacity: .3;
		    }
		}
	
		.slick-prev:before,
		.slick-next:before{
			font-family: 'slick';
		    font-size: 20px;
		    line-height: 1;
		    opacity: 1;
		    color: transparent;
		    width: 100%;
		    height: 100%;
		    display: block;

		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}

		.slick-prev{
		    left: -150px;

		   
		}
		[dir='rtl'] .slick-prev{
		    right: -150px;
		    left: auto;

		    
		}
		.slick-prev:before{
		    background-image: url('../../img/arrow-left.png');
		    background-image: url('../../img/arrow-left.svg');
		    background-size: cover;
		    background-repeat: no-repeat;

		}

		[dir='rtl'] .slick-prev:before{
		    content: '';
		}

		.slick-next{
		    right: -150px;

		}

		[dir='rtl'] .slick-next{
		    right: auto;
		    left: -150px;
		}

		.slick-next:before{
		    background-image: url('../../img/arrow-right.png');
		    background-image: url('../../img/arrow-right.svg');
		    background-size: cover;
		    background-repeat: no-repeat;
		}

		[dir='rtl'] .slick-next:before{
		    content: '';
		}

		.slick-dots{
		    position: absolute;
		    bottom: 20px;
		    left: 30px;
		    width: auto !important;
		    display: block;
		    width: 100%;
		    padding: 0;
		    margin: 0;
		    list-style: none;
		    text-align: left;

		    @include breakpoint($lg) {
		    	bottom: 0;
		    }

		    @include breakpoint($xs) {
		    	right: 30px;
				left: auto;
				top: 10px;
				z-index: 10;
		    }

		}
		.slick-dots li{
		    position: relative;

		    display: inline-block;

		    width: auto;
		    height: auto;
		    margin: 0 2px;
		    padding: 0;

		    cursor: pointer;

		    @include breakpoint($xs) {
		    	margin: 0;
		    }
		}
		.slick-dots li button{
		    font-size: 0;
		    line-height: 0;

		    display: block;

		    width: 20px;
		    height: 20px;
		    padding: 5px;

		    cursor: pointer;

		    color: transparent;
		    border: 0;
		    outline: none;
		    background: transparent;
		}
		.slick-dots li button:hover,
		.slick-dots li button:focus{
		    outline: none;
		}
		.slick-dots li button:hover:before,
		.slick-dots li button:focus:before{
		    opacity: 1;
		}
		.slick-dots li button:before{
		    font-family: 'slick';
		    font-size: 12px;
		    line-height: 20px;

		    position: absolute;
		    top: 0;
		    left: 0;

		    width: auto;
		    height: auto;

		    content: '•';
		    text-align: center;

		    opacity: 1;
		    color: $base;

		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}
		.slick-dots li.slick-active button:before{
		    color: $primary;
		    opacity: 1;
		}
	}

	.slider-lettre{	

		position: relative;

		.bloc-image-slider{
			position: relative;
		}

		.slick-prev,
		.slick-next{
		    font-size: 0;
		    line-height: 0;

		    position: absolute;
		    top: 50%;

		    display: block;

		    width: 50px;
		    height: 68px;
		    padding: 0;
		    -webkit-transform: translate(0, -50%);
		    -ms-transform: translate(0, -50%);
		    transform: translate(0, -50%);

		    cursor: pointer;

		    color: transparent;
		    border: none;
		    outline: none;
		    background: transparent;

		    -webkit-transition: opacity .6s ease;
            -moz-transition: opacity .6s ease;
            -ms-transition: opacity .6s ease;
            -o-transition: opacity .6s ease;
            transition: opacity .6s ease;

            @include breakpoint($lg) {
            	width: 37px;
				height: 50px;
				display: none !important;
            }
           

		    &:hover{
		    	opacity: .3;
		    }
		}
	
		.slick-prev:before,
		.slick-next:before{
			font-family: 'slick';
		    font-size: 20px;
		    line-height: 1;
		    opacity: 1;
		    color: transparent;
		    width: 100%;
		    height: 100%;
		    display: block;

		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}

		.slick-prev{
		    left: -150px;

		   
		}
		[dir='rtl'] .slick-prev{
		    right: -150px;
		    left: auto;

		    
		}
		.slick-prev:before{
		    background-image: url('../../img/arrow-left.png');
		    background-image: url('../../img/arrow-left.svg');
		    background-size: cover;
		    background-repeat: no-repeat;

		}

		[dir='rtl'] .slick-prev:before{
		    content: '';
		}

		.slick-next{
		    right: -540px;

		}

		[dir='rtl'] .slick-next{
		    right: auto;
		    left: -150px;
		}

		.slick-next:before{
		    background-image: url('../../img/arrow-right.png');
		    background-image: url('../../img/arrow-right.svg');
		    background-size: cover;
		    background-repeat: no-repeat;
		}

		[dir='rtl'] .slick-next:before{
		    content: '';
		}

		.slick-dots{
		    position: absolute;
		    bottom: 20px;
		    right: 20px;
		    width: auto !important;
		    display: block;
		    width: 100%;
		    padding: 0;
		    margin: 0;
		    list-style: none;
		    text-align: left;
		    z-index: 100;

		    @include breakpoint($sm) {
		    	bottom: 120px;
		    	right: 20px;
		    }

		    @include breakpoint($xs) {
		    	right: 30px;
				left: auto;
				bottom: auto;
				top: 20px;
				z-index: 10;
		    }

		}
		.slick-dots li{
		    position: relative;

		    display: inline-block;

		    width: auto;
		    height: auto;
		    margin: 0 2px;
		    padding: 0;

		    cursor: pointer;


		}
		.slick-dots li button{
		    font-size: 0;
		    line-height: 0;

		    display: block;

		    width: 20px;
		    height: 20px;
		    padding: 5px;

		    cursor: pointer;

		    color: transparent;
		    border: 0;
		    outline: none;
		    background: transparent;
		}
		.slick-dots li button:hover,
		.slick-dots li button:focus{
		    outline: none;
		}
		.slick-dots li button:hover:before,
		.slick-dots li button:focus:before{
		    opacity: 1;
		}
		.slick-dots li button:before{
		    font-family: 'slick';
		    font-size: 12px;
		    line-height: 20px;

		    position: absolute;
		    top: 0;
		    left: 0;

		    width: auto;
		    height: auto;

		    content: '•';
		    text-align: center;

		    opacity: 1;
		    color: $base;

		    -webkit-font-smoothing: antialiased;
		    -moz-osx-font-smoothing: grayscale;
		}
		.slick-dots li.slick-active button:before{
		    color: white;
		    opacity: 1;

			@include breakpoint($xs) {
				color: $primary;
			}
		}
	}
}





